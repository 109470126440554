import React from 'react';
import VideoPlayer6 from '../components/VideoPlayer6';








const MyLife= () => {
    
  


    return (
        <>


            {/* blog detail content - start */}
            <div className="blog-detail page-bg">
                <div className="blog-detail-wrapper page-bg-wrapper">
                    <div className="container page-bg-content">
                        <div className="row">
                        <div className="col-lg-12">
                       <h1 className="my-journey-title">My Life Journey</h1>
                       <p style={{padding: '15px'}} className="paragraph-lg c-grey">Morris The Connector is a man who has seen the darkest sides of life, but has emerged on the other side with a renewed sense of purpose. He was once a gangster and drug dealer, but has since turned his life around and now runs his own chauffeur business. Morris has experienced tremendous loss throughout his life, having lost many family members to death. However, instead of letting his pain consume him, he has used it as fuel to help others.</p>
   
                       <p style={{padding: '15px'}} className="paragraph-lg c-grey">One of Morris's greatest achievements has been setting up a charity to help disadvantaged people, particularly young people who are growing up without role models. Morris understands the challenges that these young people face, having grown up in a tough environment himself. He knows firsthand how important it is to have positive influences in one's life, and he is determined to be that influence for others.</p>

                       <VideoPlayer6 />

                       <p style={{padding: '15px'}} className="paragraph-lg c-grey">Through his charity, Morris has organized numerous fundraisers and events, with the goal of raising awareness and funds for those in need. His efforts have not gone unnoticed, and he has even had the opportunity to meet with the Prime Minister at Downing Street to discuss his work and how the government can support it.</p>

                       <p style={{padding: '15px'}} className="paragraph-lg c-grey">Morris's journey has been a long and difficult one, but he has emerged on the other side with a renewed sense of purpose and a desire to help others. His experiences have given him a unique perspective on life, and he is determined to use that perspective to make a difference in the world.</p>

                       <p style={{padding: '15px'}} className="paragraph-lg c-grey">we will take a closer look at Morris's life, exploring the challenges he has faced and the remarkable achievements he has made. We will also delve into the work of his charity, examining the impact it has had on the lives of those it has helped. Through Morris's story, we will gain insight into the power of perseverance and the potential for positive change, even in the face of adversity..</p>

                      </div>
                        </div>
                    </div>
                </div>
              
            </div>

        
                   
         
          

        </>
    );
};

export default MyLife;
