import React from "react";
import YoutubeEmbed2 from "../components/YoutubeEmbed2";
import culture1 from "../../assets/images/culture_01.jpg";
import culture2 from "../../assets/images/culture_02.jpg";
import culture3 from "../../assets/images/culture_03.jpg";
import culture4 from "../../assets/images/culture_04.jpg";
import culture5 from "../../assets/images/culture_05.jpg";
import VideoPlayer1 from "../components/VideoPlayer1";
const Projects = () => {
  return (
    <div className="container">
      {/* Support for 7th Day Adventist School in Ethiopia*/}
      <div style={{ marginTop: 130 }} className="row">
        <div className="col-lg-12">
          <div className="intro-content">
            <h3 style={{ marginTop: 20 }} className="right-align">
              Support for 7th Day Adventist School in Ethiopia
            </h3>
            <p className="paragraph-lg c-grey">
              <h4>
                <strong>Overview:</strong>
              </h4>
              At Morris The Connector CIC, we believe in the power of education
              to transform lives and communities. Our commitment to this belief
              led us to support the 7th Day Adventist School in Ethiopia through
              a donation of stationary supplies.
            </p>

            <p className="paragraph-lg c-grey">
              <h4>
                <strong>Goals:</strong>
              </h4>
              <strong>Access to Education:</strong> By providing stationary
              supplies, we aim to support the educational journey of students at
              the 7th Day Adventist School, ensuring they have the necessary
              tools to excel in their studies.
            </p>

            <p className="paragraph-lg c-grey">
              <strong>Empowerment:</strong> Access to education empowers
              individuals, families, and communities, breaking the cycle of
              poverty and opening doors to a brighter future.
            </p>

            <p className="paragraph-lg c-grey">
              <strong>Impact:</strong> Our impact of donating stationary
              supplies directly impacts the learning environment at the 7th Day
              Adventist School by: Equipping students with essential tools such
              as notebooks, pens, pencils, and other stationary items. Enhancing
              the educational experience by removing barriers to learning and
              fostering a conducive atmosphere for academic growth. Supporting
              the dedicated efforts of teachers and staff in providing quality
              education to students.
            </p>

            <p className="paragraph-lg c-grey">
              <strong>Achievements:</strong> Through the generous contributions
              of our supporters, we were able to donate a substantial quantity
              of stationary supplies to the 7th Day Adventist School in
              Ethiopia. The donation has been well-received by the school
              administration, teachers, students, and their families, reflecting
              the positive impact it has made on the educational community.
            </p>

            <p className="paragraph-lg c-grey">
              <strong>Future Plans:</strong> We remain committed to supporting
              educational initiatives that promote access to quality education
              for all. We plan to continue our partnership with the 7th Day
              Adventist School and explore additional ways to support their
              mission of providing education and empowerment to students in
              Ethiopia.
            </p>

            <VideoPlayer1 />
          </div>
        </div>
      </div>

      {/* Support for Sitota Culture Exchange*/}
      <div style={{ marginTop: 30 }} className="row">
        <div className="col-lg-12">
          <div className="intro-content">
            <h3 style={{ marginTop: 20 }} className="right-align">
              Support for Sitota Culture Exchange
            </h3>
            <div className="image-grid-3">
            <img
              src={culture1}
              className="grid-image-3"
              alt="Morris The Connector - Quote 1 "
            />
            <img
              src={culture2}
              className="grid-image-3"
              alt="Morris The Connector - Quote 1 "
            />
            <img
              src={culture3}
              className="grid-image-3"
              alt="Morris The Connector - Quote 1 "
            />
            <img
            src={culture4}
            className="grid-image-3"
            alt="Morris The Connector - Quote 1 "
          />
          <img
          src={culture5}
          className="grid-image-3"
          alt="Morris The Connector - Quote 1 "
        />

          </div>
            <p className="paragraph-lg c-grey">
              <h4>
                <strong>Overview:</strong>
              </h4>
              The CIC is dedicated to fostering cultural exchange and
              understanding across communities. One of the ways we fulfill this
              mission is by supporting initiatives like the Sitota Culture
              Exchange through donations of certificates, t-shirts, and good
              boxes.
            </p>

            <p className="paragraph-lg c-grey">
              <h4>
                <strong>Goals:</strong>
              </h4>
              <strong>Cultural Exchange:</strong> Our support for the Sitota
              Culture Exchange aims to promote cross-cultural understanding and
              appreciation among participants.
            </p>

            <p className="paragraph-lg c-grey">
              <strong>Community Engagement:</strong> By providing donations of
              certificates, t-shirts, and good boxes, we aim to enhance the
              experience of participants and contribute to the success of the
              cultural exchange program.
            </p>

            <p className="paragraph-lg c-grey">
              <strong>Impact:</strong> Providing participants with tangible
              tokens of appreciation and recognition through certificates and
              t-shirts. Supporting the logistical and operational needs of the
              program through the provision of good boxes and other essential
              supplies. Facilitating meaningful connections and interactions
              among participants from diverse cultural backgrounds.
            </p>

            <p className="paragraph-lg c-grey">
              <strong>Achievements:</strong> Our donations of certificates,
              t-shirts, and good boxes have enriched the experience of
              participants in the Sitota Culture Exchange, contributing to the
              success and positive outcomes of the program. Feedback from
              participants and organizers has been overwhelmingly positive,
              highlighting the significance of our contributions in promoting
              cultural exchange and understanding..
            </p>

            <p className="paragraph-lg c-grey">
              <strong>Future Plans:</strong> We are committed to continuing our
              support for initiatives like the Sitota Culture Exchange that
              promote cultural exchange and understanding. Moving forward, we
              plan to explore additional ways to expand our impact and reach in
              fostering cross-cultural connections and dialogue.
            </p>
          </div>
        </div>
      </div>
      {/* Emir Section Ends */}

      {/* Musically Unorthodox Inspirational Talk */}
      <div style={{ marginTop: 30 }} className="row">
        <div className="col-lg-12">
          <div className="intro-content">
            <h3 style={{ marginTop: 20 }} className="right-align">
              Musically Unorthodox Inspirational Talk
            </h3>
            <p className="paragraph-lg c-grey">
              <h4>
                <strong>Overview:</strong>
              </h4>
              Morris The Connector CIC is passionate about empowering aspiring
              musicians to pursue their dreams and break barriers in the music
              industry. As part of this commitment, Morris The Connector
              delivered an inspirational talk to aspiring musicians
              participating in the Musically Unorthodox program.
            </p>
            <YoutubeEmbed2 />
            <p className="paragraph-lg c-grey">
              <h4>
                <strong>Goals:</strong>
              </h4>
              <strong>Empowerment:</strong> The goal of the inspirational talk
              was to inspire and empower aspiring musicians to overcome
              challenges, embrace their unique talents, and pursue their musical
              aspirations with confidence.
            </p>

            <p className="paragraph-lg c-grey">
              <strong>Knowledge Sharing:</strong> Through sharing personal
              experiences and insights, Morris The Connector aimed to provide
              valuable knowledge and advice to participants, equipping them with
              the tools they need to succeed in their musical journeys.
            </p>

            <p className="paragraph-lg c-grey">
              <strong>Impact:</strong> Motivating and inspiring participants to
              pursue their musical passions with determination and resilience.
              Providing practical guidance and advice on navigating the music
              industry, building a personal brand, and overcoming obstacles
              along the way. Fostering a sense of community and camaraderie
              among participants, encouraging collaboration and mutual support
              in their musical pursuits.
            </p>
         

            <p className="paragraph-lg c-grey">
              <strong>Achievements:</strong> The inspirational talk received positive feedback from participants, who found Morris The Connector's insights and encouragement invaluable in their musical endeavors.
              Many participants expressed gratitude for the opportunity to learn from Morris The Connector's experiences and gain a deeper understanding of what it takes to succeed as a musician.
            </p>

            <p className="paragraph-lg c-grey">
              <strong>Future Plans:</strong> Morris The Connector CIC is committed to continuing its support for aspiring musicians through mentorship, workshops, and other initiatives aimed at nurturing talent and fostering growth in the music industry. Moving forward, we plan to explore additional opportunities to engage with aspiring musicians and provide them with the guidance and support they need to thrive in their musical careers.
            </p>
           
          </div>
   
        </div>
      </div>
      {/* Emir Section Ends */}
    </div>
  );
};

export default Projects;
