import React from 'react';

// react router dom
// import { Link } from 'react-router-dom';

// components
// import PageHeader from '../components/PageHeader';
// import Button from '../components/Button';
import ContactForm from '../components/ContactForm';

// images
// import header_image from '../../assets/images/page-header-img.jpg';
// import pattern1 from '../../assets/images/patterns/pattern.png';
import pattern2 from '../../assets/images/patterns/pattern-2.png';



const Contact = () => {
    return (
        <>
      


            {/* contact form - start */}
            <div style={{marginTop: "80px"}} className="contact-form pattern-bg">
                <div className="contact-form-wrapper">
                    <div className="container pattern-bg-content">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="contact-form-header">
                                    <h2 className="ln-ht-44">Write Us A Message</h2>
                                    <p className="large c-grey">Thank you for your interest in contacting us. We would love to hear from you! Whether you have a question, feedback, or just want to say hello, please fill out the form below and we'll get back to you as soon as possible.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <ContactForm/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pattern-bg-image" style={{backgroundImage: `url(${pattern2})`}}></div>
            </div>
            {/* contact form - end */}

          
        </>
    );
};

export default Contact;
