import React from "react";
// react router dom
// import { Link } from "react-router-dom";
import { useSpring, animated } from "react-spring";
// sliders
import TestimonialSlider from "../components/TestimonialSlider";
// Images //
import testimonial_img_1 from "../../assets/images/testimonial-bg-1.jpg";
import intro_bg from "../../assets/images/intro-bg.jpg";

import one from "../../assets/images/1.jpg";
import two from "../../assets/images/culture_01.jpg";
import three from "../../assets/images/3.jpg";
import four from "../../assets/images/4.jpg";
import Ethiopia from "../../assets/images/eth.png";
import Siota from "../../assets/images/siota.png";
import Music from "../../assets/images/music.png";
import VideoPlayer1 from "../components/VideoPlayer1";
import VideoPlayer4 from "../components/VideoPlayer4";
import VideoPlayer5 from "../components/VideoPlayer5";
import VideoPlayer7 from "../components/VideoPlayer7";

const Index = () => {
  // Define animation for the paragraph
  const paragraphAnimationProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 800, // Delay for the paragraph to appear
  });
  // Animation for the entire content
  const contentAnimationProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 500, // Delay for the entire content to appear
  });

  // Animation for individual elements
  const introContentAnimationProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 1000, // Delay for intro content to appear
  });

  const testimonialAnimationProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 1500, // Delay for testimonial section to appear
  });

  const teamSectionAnimationProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 2000, // Delay for team section to appear
  });

  const blogSectionAnimationProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 2500, // Delay for blog section to appear
  });

  const testimonials = [
    {
      testimony:
        " Morris is a powerful speaker who connected with the entire audience who were made up of the diversity of London. From students, city professionals, career changers and those looking for employment. His powerful message of resilience, purpose and action inspired us all to take action. He is a person that I would recommend for speaking engagements and one who we would like to have back again at Generation Success",
      name: "James Adeleke FRSA - CEO Generation Success",
    },
    {
      testimony:
        'Morris "The Connector" Lothian has presented at a number of our events and he has never disappointed with his valuable messages and insights around business and leadership. Mesmerising, inspirational and incredibly motivational are some of the words our audience used in feedback. He has a fascinating personal story and an exceptional business outlook, making him the perfect fit for our audiences.',
      name: "Sonia Brown MBE - Founder National Black Women Network",
    },
    {
      testimony:
        'We run motivational workshops in Schools and Universities all over the UK. It was my pleasure to invite Morris "The Connector" Lothian into several of my Schools. From the very start of his workshop he has the ability to capture their imaginations. He has met some of the worlds most famous celebrities and yet has kept his feet on solid ground. His life journey is filled with life skills and he weaves his personality in to the story telling',
      name: "Andrew Muhammad - Director Reel Talk Action LTD",
    },
  ];

  return (
    <>
      {/* Hero Slider 2 - start */}

      {/* Hero Slider 2 - end */}

      <div className="image intro-bg">
        <animated.img style={introContentAnimationProps} src={intro_bg} alt="intro-bg" />
      </div>


      {/* icon text section - start */}
      <div className="icon-text">
        <div className="icon-text-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="intro-content">
                  <h1 className="title-intro animate__animated animate__fadeInUp animate__delay-1s">
                    <strong>Pathways of Support: Morris The Connector CIC</strong>
                  </h1>
                  <animated.p style={paragraphAnimationProps} className="paragraph-lg c-grey animate__animated animate__fadeInUp animate__delay-2s">
                    Greetings, everyone! I'm Morris The Connector, and I've
                    embarked on a remarkable journey of transformation. Despite
                    a challenging upbringing and a path that led me through
                    adversity, I found my true self. It all began when I started
                    attending church with my mother, and a newfound light
                    started shining in my life.
                  </animated.p>
                  <p className="paragraph-lg c-grey animate__animated animate__fadeInUp animate__delay-6s">
                    Throughout the years, I faced heart-wrenching losses,
                    including the tragic passing of my son, siblings, parents,
                    and even my wife. However, instead of asking "why me," I
                    began to say, "try me." I realized these experiences were
                    shaping me into the person I was meant to be. It was as if
                    God was molding me through trials to discover my inner self,
                    turning my gory past into my glorious present.
                  </p>
                  <p className="paragraph-lg c-grey animate__animated animate__fadeInUp animate__delay-8s">
                    Against all odds, I navigated life's obstacles and became a
                    successful businessman, working with renowned pop stars like
                    Justin Bieber, Beyonce, and Whitney Houston through my
                    chauffeur company, established in 2003. I also faced
                    personal tragedies such as the loss of my 18-month-old son,
                    Dwain, and many other adversities. These experiences have
                    taught me the value of perseverance, resilience, and the
                    importance of giving back to the community.
                  </p>

                  <p className="paragraph-lg c-grey animate__animated animate__fadeInUp animate__delay-10s">
                    Through Morris The Connector CIC, I am dedicated to
                    providing support and resources to children who may be
                    facing similar challenges to what I experienced. I believe
                    that by providing them with the necessary tools and
                    guidance, we can help them overcome their obstacles and
                    reach their full potential.
                  </p>

                  <p className="paragraph-lg c-grey">
                    <strong>
                      I even had the privilege of visiting No10 Downing Street
                      to discuss the issue of knife crime with the Prime
                      Minister.
                    </strong>
                  </p>


                  <p className="paragraph-lg c-grey">
                    My inspiring journey led me to become an International
                    Inspiration Speaker and Transformation Coach, I have also
                    written a book about my life.
                  </p>

                  <p className="paragraph-lg c-grey">
                    With the rising concern of knife and gun crime among today's
                    youth, I founded the charity morristheconnectorcic.org.uk
                    and plan to establish an Alternative School of Learning in
                    England and Ethiopia, teaching life skills and empowerment.
                    I'm frequently invited to schools to share my wisdom with
                    young minds.
                  </p>

                  <p className="paragraph-lg c-grey">
                    Additionally, I launched "Wear Your Crown" to speak on stage
                    and inspire others to find their inner light, just as I did.
                    Everyone needs guidance, and I believe in the power of
                    coaching.
                  </p>

                  <p className="paragraph-lg c-grey">
                    My professional experience allows me to help today's youth,
                    who are the future. In life, focus is paramount. Like the
                    sun, we must concentrate our energy to make an impact.
                    Having a vision is crucial, as it provides direction.
                  </p>

                  <p className="paragraph-lg c-grey">
                    Without it, we risk getting lost in life's journey. A dream
                    is like a roadmap to your life's destiny. I've developed a
                    coaching system called "Focus and believing mindset
                    Programme," designed to guide clients through a process of
                    accountability and breakthrough. It helps individuals reset
                    their minds and take small steps toward becoming incredible.
                  </p>

                  <p className="paragraph-lg c-grey">
                    This service is particularly valuable in today's challenging
                    times, as many clients seek support due to the effects of
                    Covid19.
                  </p>

                  <p className="paragraph-lg c-grey">
                    Remember, having a vision is essential, and with the right
                    guidance, you can overcome life's obstacles and become the
                    incredible person you're meant to.
                  </p>

                  <div className="image-grid">
                    <img
                      src={Ethiopia}
                      className="grid-image"
                      alt="Morris The Connector - Quote 1 "
                    />
                    <img
                      src={Siota}
                      className="grid-image"
                      alt="Morris The Connector - Quote 1 "
                    />
                    <img
                      src={Music}
                      className="grid-image"
                      alt="Morris The Connector - Quote 1 "
                    />

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* icon text section - end */}


      <div className="container">
        <div className="intro-content">

          <br />
          <VideoPlayer4 />

          <h2 className="title-intro-2">
            <strong>Morris The Connector talks @ Pioneer Secondary Academy</strong>
          </h2>
          <p className="paragraph-lg c-grey">
            Get ready for an inspiring journey at Pioneer Secondary Academy (PSA), a remarkable Sikh faith school that opened on 1st May 2022, under the guidance of the Sikh Academies Trust (SAT). Join Morris The Connector on an unforgettable voyage of inspiration and personal transformation within this vibrant educational community. We firmly believe that every student, regardless of their ability or background, can achieve greatness and shape a bright future for themselves.
          </p>
          <p className="paragraph-lg c-grey">
            In this captivating talk at PSA, located in the heart of West London, I'll reveal the keys to unlocking your boundless potential, turning your dreams into reality, and igniting the best version of yourself.
          </p>
          <p className="paragraph-lg c-grey">
            This video isn't just content; it's a catalyst for profound change! Click that play button and embark on a life-altering journey with me, right within the nurturing environment of PSA! Don't forget to subscribe, like, and share to spread this wave of inspiration far and wide!
          </p>
          <br />
          <br />

          <VideoPlayer7 />

          <h2 className="title-intro-2">
            <strong>Morris The Connector - PSA Testimonial</strong>
          </h2>
       
        </div>


      </div>
      {/* tab section - start */}

      {/* tab section - end */}

      {/* testimonial slider section - start */}
      <div className="testimonial testimonial-1">
        <div className="testimonial-wrapper">
          <div className="container">
            <div className="row">
              <div className="testimonial-heading-content">
                <h2 className="f-w-400 heading-40">Testimonials</h2>
              </div>
            </div>
            <div className="row">
              <TestimonialSlider testimonials={testimonials} />
            </div>
          </div>
          <div
            className="background-img"
            style={{ backgroundImage: `url(${testimonial_img_1})` }}
          ></div>
        </div>
        <div className="icon-text-box icon-text-box-large txt-center">
          <h3 className="f-w-400 c-dark-2">
            My journey has been inspiring to others which has propelled me to
            becoming a inspirational speaker and transformational Coach.
          </h3>
          <h4 className="f-w-400 c-grey">" Morris The Connector "</h4>
        </div>
      </div>
      {/* testimonial slider section - end */}
      <VideoPlayer5 />
      {/* Our Team Section Starts*/}
      <div className="intro">
        <div className="intro-wrapper">
          <div className="container">
            <div className="row">
              <h1 style={{ paddingBottom: 50 }}>Our Team</h1>
              <br></br>

              <div className="col-lg-12">
                <div className="intro-content">

                  <h3 className="right-align">Morris The Connector</h3>
                  <p className="paragraph-lg c-grey">
                    I wanted to take this time to let you know more about me,
                    how I got to this point in my life and, more importantly,
                    how I can use the skills I have learned to help you.
                  </p>

                  <p className="paragraph-lg c-grey">
                    Like almost every single one of you reading this, my life
                    has had its fair share of good times and bad. I’ve been
                    blessed to have been able to reveal in some incredible
                    highlights, but they have also been interspersed with
                    tragedy that would have broken many others. Trust me, it
                    came close to breaking me too, but here I am.
                  </p>

                  <p className="paragraph-lg c-grey">
                    From growing up in the harsh inner city, I’ve known what
                    it’s like to be down there looking up. From times when I’ve
                    had absolutely nothing, I’ve worked hard. Eventually leading
                    me to start up my own Executive chauffeur business working
                    with clients that have included Floyd Mayweather, Beyonce,
                    Justin Bieber and the late great Whitney Houston to name a
                    few.
                  </p>
                </div>
              </div>
            </div>
            <br />
            {/* Eric's Section */}
            <div className="row">
              <div className="col-lg-12">
                <div className="intro-content">
                  <h3 style={{ marginTop: 20 }} className="right-align">
                    Eric Appiah-Agyemang
                  </h3>

                  <p className="paragraph-lg c-grey">
                    Eric Appiah-Agyemangan experienced mentor/ teaching
                    assistant who has worked across a range of settings in
                    supporting children and young people with barriers to
                    learning such as SEMH, Autism and special educational needs.
                  </p>

                  <p className="paragraph-lg c-grey">
                    He has the skills in collaborative working across year
                    groups and departments as well as with external
                    stakeholders. Excellent empathy that understands the various
                    needs of learners and supporting these accordingly through a
                    series of learning tasks whilst promoting independence and
                    self-reliance.
                  </p>

                  <p className="paragraph-lg c-grey">
                    Eric is a great team player with excellent communication
                    skills and the ability to establish a rapport with pupils,
                    parents and staff. A leader who is able to take own
                    initiative and find solutions in difficult situations. He is
                    also very organised and is able to work under pressure and
                    meet deadlines. With a keenness to see every pupil succeed
                    and achieve their potential.
                  </p>
                </div>
              </div>
            </div>
            {/* Eric Section Ends */}
            <br />
            {/* Emir Section */}
            <div style={{ marginBottom: 100 }} className="row">
              <div className="col-lg-12">
                <div className="intro-content">
                  <h3 style={{ marginTop: 20 }} className="right-align">
                    Emir
                  </h3>
                  <p className="paragraph-lg c-grey">
                    His deepest passion lies in helping people to achieve their
                    goals and improve. An excellent leader and communicator with
                    first className people management and interpersonal skills,
                    he is conscientious, diligent and hardworking and
                    continually looking for ways to build on the experience and
                    skills that have developed to date.
                  </p>

                  <p className="paragraph-lg c-grey">
                    He takes on new challenges with determination and approaches
                    them with confidence, setting high personal standards and
                    always giving 100% to achieve targets and deliver results.
                  </p>

                  <p className="paragraph-lg c-grey">
                    Emir always work tirelessly to implement improved
                    performance and develop strong customer and colleague
                    relationships. His greatest satisfaction comes from knowing
                    his impact on people has enriched their life and made them
                    better in as many ways as they are willing to take on to
                    change.
                  </p>
                </div>
              </div>
            </div>
            {/* Emir Section Ends */}
          </div>
        </div>
      </div>
      {/* Our Team Section Ends*/}

      <VideoPlayer1 />

      {/* blog detail content - start */}
      <div className="blog-detail page-bg">
        <div className="blog-detail-wrapper page-bg-wrapper">
          <div className="container page-bg-content">
            <div className="row">
              <h3 style={{ paddingBottom: 50 }}>Project Overview</h3>
              <br></br>

              <div className="image-grid">
                <img
                  src={one}
                  className="grid-image"
                  alt="Morris The Connector - Quote 1 "
                />
                <img
                  src={two}
                  className="grid-image"
                  alt="Morris The Connector - Quote 2 "
                />
                <img
                  src={three}
                  className="grid-image"
                  alt="Morris The Connector - Quote 3"
                />
                <img
                  src={four}
                  className="grid-image"
                  alt="Morris The Connector - Quote 4"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* blog detail content - end */}
    </>
  );
};

export default Index;
