import React from 'react'
import myVideo from "../../assets/video/video_my_journey.mp4";

const VideoPlayer6 = () => {
  return (
    <div style={{ maxWidth: '100%', height: 'auto', marginTop:'50px' }}>
    <video
      style={{ width: '100%', height: 'auto' }}
      controls
    >
      <source src={myVideo} type="video/mp4" />
    </video>
  </div>
  )
}

export default VideoPlayer6