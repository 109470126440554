import React from "react";
import VideoPlayer2 from "../components/VideoPlayer2";
import VideoPlayer3 from "../components/VideoPlayer3";
import donateImage from "../../assets/images/boxing.png";


const Fundraisers = () => {
  return (
    <>
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="intro-content">
                <h2>Morris The Connector - Growth Mindset Fundraiser</h2>
                <p className="paragraph-lg c-grey">
                  We are raising funds to provide youth with life skills that mainstream schooling does not provide. Our target is £10,000.
                </p>
                <p className="paragraph-lg c-grey">
                  In light of the rising incidents of knife and gun crime among today's youths, I have decided to use my knowledge and wisdom to establish the charity Morristheconnectorcic.org.uk.
                </p>
                <p className="paragraph-lg c-grey">
                  I am also committed to raising funds for my charity by participating in a boxing event. The charity is called the Fixed Mindset and Growth Mindset Program, aimed at providing a better reality for disadvantaged youths to live in society and promote morality.
                </p>
                <p className="paragraph-lg c-grey">
                  Morris The Connector CIC is dedicated to teaching life skills and empowerment to children in the UK and Africa. Our mission is to provide children with the tools and resources they need to succeed in life, regardless of their background or circumstances.
                </p>
              </div>
            </div>
        
          
            
          </div>
        </div>
      </div>

      <VideoPlayer3 />

      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="intro-content">
                <h2>Empowering Children Worldwide: Morris the Connector's Fundraiser for Life Skills Centers and Orphan Schools</h2>
                <p className="paragraph-lg c-grey">
                  At Morris the Connector, we believe that every child deserves the opportunity to reach their full potential. Unfortunately, many children around the world do not have access to the resources they need to thrive.
                </p>
                <p className="paragraph-lg c-grey">
                  In addition, we are also proud to announce our latest project, "Wear Your Own Crown," which involves opening a school in Zambia for orphans. This project aims to provide orphans with education and to empower them to become successful and self-sufficient individuals.
                </p>
                <p className="paragraph-lg c-grey">
                  Unfortunately, it has been incredibly difficult for us to raise the funds we need to make these projects a reality. That is why we are turning to you for help.
                </p>
                <p className="paragraph-lg c-grey">
                  With your help, we can make a difference in the lives of children around the world. Your donation will go directly towards providing children with the resources they need to succeed in life and helping us create a brighter future for generations to come.
                </p>
                <div className="button-flex">
                  <div className="button-group">
                    <button
                      href="https://gofund.me/db984b0e"
                     
                    >
                      Donate Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <VideoPlayer2 />
    </>
  );
};

export default Fundraisers;
