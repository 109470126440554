import React from "react";
import myVideo from "../../assets/video/morris_002.mp4";



const VideoPlayer1 = () => (
  <div style={{ maxWidth: '100%', height: 'auto' }}>
  <video
    style={{ width: '100%', height: 'auto' }}
    controls
  >
    <source src={myVideo} type="video/mp4" />
  </video>
</div>
);



export default VideoPlayer1;