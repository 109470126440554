import React from "react";


const YoutubeEmbed = () => (
  <div className="video-responsive">
    <iframe
      width="653"
      height="480"
      src={`https://www.youtube.com/embed/0ym6tVLMlcs?si=135PH74ScyvWOkm_`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Morris The Connector"
      poster="../../assets/images/video_cover.jpg"
    />
  </div>
);



export default YoutubeEmbed;