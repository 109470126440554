import { useState } from "react";
import {
  FaBars,
  FaTimes,
  FaTiktok,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
  FaMailBulk,
  FaPhoneSquare
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "../../assets/css/main.css";
import logo from "../../assets/images/logo.png";

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header>
      <div onClick={toggleMenu} className="nav-btn">
        {menuOpen ? (
          <FaTimes style={{ color: "white" }} />
        ) : (
          <FaBars style={{ color: "white" }} />
        )}
      </div>
      <img className="menu-logo" src={logo} alt="Logo" />

      <div className="social-icons-2">
      <a href="https://www.instagram.com/iammorristheconnector">
        <FaInstagram className="icons" />
      </a>
      <a href="https://www.facebook.com/morristheconnector">
        <FaFacebook className="icons" />
      </a>
      <a href="https://www.tiktok.com/@morristheconnector">
      <FaTiktok className="icons" />
    </a>
      <a href="https://www.youtube.com/channel/UCuaYmeHX_-BhMz9ijmOCOWg">
        <FaYoutube className="icons" />
      </a>
      <a href="https://www.linkedin.com/in/morristheconnector">
        <FaLinkedin className="icons" />
      </a>

    </div>

      <nav className={` ${menuOpen ? "responsive_nav" : "nav"}`}>
      <img className="menu-logo-2" src={logo} alt="Logo" />
        <Link to={"/"} onClick={toggleMenu}>
          Home
        </Link>
     
        <Link to={"/my-journey"} onClick={toggleMenu}>
          My Journey
        </Link>
        <Link to={"/fundraisers"} onClick={toggleMenu}>
        Donate
      </Link>
      <Link to={"/projects"} onClick={toggleMenu}>
      Projects
    </Link>
        <Link to={"/contact"} onClick={toggleMenu}>
          Contact
        </Link>
      <div className="email-phone">
      <h4>
      Email Us:
      <a href="">
      <FaMailBulk className="icons" />
      </a>
      <a href=""></a>
      </h4>
      <h4>
      Call Us:
      <a href="">
      <FaPhoneSquare className="icons" />
      </a>
      <a href=""></a>
      </h4>
      </div>
      


      </nav>

      <div className="social-icons">
        <a href="https://www.instagram.com/iammorristheconnector">
          <FaInstagram className="icons" />
        </a>
        <a href="https://www.facebook.com/morristheconnector">
          <FaFacebook className="icons" />
        </a>
        <a href="https://www.tiktok.com/@morristheconnector">
        <FaTiktok className="icons" />
      </a>
        <a href="https://www.youtube.com/channel/UCuaYmeHX_-BhMz9ijmOCOWg">
          <FaYoutube className="icons" />
        </a>
        <a href="https://www.linkedin.com/in/morristheconnector">
          <FaLinkedin className="icons" />
        </a>
      </div>
    </header>
  );
}

export default Header;
