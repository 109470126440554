import React from 'react'



const VideoPlayer4 = () => {



  return (
    <div className="video-responsive">

    <iframe
    
      src="https://www.youtube.com/embed/srAgBIpSCQU?si=S5WFCfeEXBqcVoXp"
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    ></iframe>
  </div>

  )
}

export default VideoPlayer4